export const sitesNameMapping = {
  shortNameToAcronym: {
    "RENCI": "RENC",
    "UKY": "UKY",
    "LBNL": "LBNL",
    "StarLight": "STAR",
    "MAX": "MAX",
    "TACC": "TACC",
    "UMich": "MICH",
    "UMass": "MASS",
    "Utah": "UTAH",
    "NCSA": "NCSA",
    "Washington": "WASH",
    "Dallas": "DALL",
    "Salt Lake City": "SALT",
    "UCSD": "UCSD",
    "GPN": "GPN",
    "FIU": "FIU",
    "Clemson": "CLEM",
    "GaTech": "GATECH",
    "Los Angeles": "LOSA",
    "New York": "NEWY",
    "Kansas City": "KANS",
    "Atlanta": "ATLA",
    "Seattle": "SEAT",
    "Princeton": "PRIN",
    "IU": "INDI",
    "PSC": "PSC",
    "Rutgers": "RUTG",
    "SRI": "SRI",
    "CERN": "CERN",
    "University of Bristol": "BRIST",
    "University of Amsterdam": "AMST",
    "University of Tokyo": "TOKY",
    "HAWI": "HAWI",
    "EDC": "EDC",
    "EDUKY": "EDUKY"
  },
  acronymToShortName: {
    "RENC": "RENCI",
    "UKY": "UKY",
    "LBNL": "LBNL",
    "STAR": "StarLight",
    "MAX": "MAX",
    "TACC": "TACC",
    "MICH": "UMich",
    "MASS": "UMass",
    "UTAH": "Utah",
    "NCSA": "NCSA",
    "WASH": "Washington",
    "DALL": "Dallas",
    "SALT": "Salt Lake City",
    "UCSD": "UCSD",
    "GPN": "GPN",
    "FIU": "FIU",
    "CLEM": "Clemson",
    "GATECH": "GaTech",
    "LOSA": "Los Angeles",
    "NEWY": "New York",
    "KANS": "Kansas City",
    "ATLA": "Atlanta",
    "SEAT": "Seattle",
    "PRIN": "Princeton",
    "INDI": "IU",
    "PSC": "PSC",
    "RUTG": "Rutgers",
    "SRI": "SRI",
    "CERN": "CERN",
    "BRIST": "University of Bristol",
    "AMST": "University of Amsterdam",
    "TOKY": "University of Tokyo",
    "HAWI": "HAWI",
    "EDC": "EDC",
    "EDUKY": "EDUKY"
  },
  acronymToLongName: {
    "RENC": "RENCI",
    "UKY": "UKY",
    "LBNL": "LBNL",
    "STAR": "StarLight",
    "MAX": "University of Maryland/MAX",
    "TACC": "TACC",
    "MICH": "University of Michigan",
    "MASS": "UMass",
    "UTAH": "University of Utah",
    "NCSA": "NCSA",
    "WASH": "Washington",
    "DALL": "Dallas",
    "SALT": "Salt Lake City",
    "UCSD": "UCSD",
    "GPN": "GPN",
    "FIU": "Florida International University",
    "CLEM": "Clemson",
    "GATECH": "Georgia Tech",
    "LOSA": "Los Angeles",
    "NEWY": "New York",
    "KANS": "Kansas City",
    "ATLA": "Atlanta",
    "SEAT": "Seattle",
    "PRIN": "Princeton",
    "INDI": "Indiana University",
    "PSC": "PSC",
    "RUTG": "Rutgers",
    "SRI": "SRI",
    "CERN": "CERN",
    "BRIST": "University of Bristol",
    "AMST": "University of Amsterdam",
    "TOKY": "University of Tokyo",
    "HAWI": "University of Hawai'i",
    "EDC": "EDC",
    "EDUKY": "EDUKY"
  },
  shortToLongName: {
    "RENCI": "RENCI",
    "UKY": "UKY",
    "LBNL": "LBNL",
    "StarLight": "StarLight",
    "MAX": "University of Maryland/MAX",
    "TACC": "TACC",
    "UMich": "University of Michigan",
    "UMass": "UMass",
    "Utah": "University of Utah",
    "NCSA": "NCSA",
    "Washington": "Washington",
    "Dallas": "Dallas",
    "Salt Lake City": "SALT",
    "UCSD": "UCSD",
    "GPN": "GPN",
    "FIU": "Florida International University",
    "Clemson": "Clemson",
    "GaTech": "Georgia Tech",
    "Los Angeles": "Los Angeles",
    "New York": "New York",
    "Kansas City": "Kansas City",
    "Atlanta": "Atlanta",
    "Seattle": "Seattle",
    "Princeton": "Princeton",
    "IU": "Indiana University",
    "PSC": "PSC",
    "Rutgers": "Rutgers",
    "SRI": "SRI",
    "CERN": "CERN",
    "University of Bristol": "University of Bristol",
    "University of Amsterdam": "University of Amsterdam",
    "University of Tokyo": "University of Tokyo",
    "HAWI": "University of Hawai'i",
    "EDC": "EDC",
    "EDUKY": "EDUKY"
  },
}